



















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "@/service/authService";
const auth = new AuthService();
const StoreLoading = namespace("Loading");
@Component
export default class Login extends Vue {
  public currentUser = "";
  public accessTokenExpired: boolean | undefined = false;
  public isLoggedIn = false;

  @StoreLoading.Action
  private IsLoading!: (input: boolean) => void;

  get username(): string {
    return this.currentUser.toString();
  }

  public login() {
    this.IsLoading(true);
    auth.login();
  }

  public logout() {
    auth.logout();
  }

  // public mounted() {
  //   auth.getUser().then((user) => {
  //     if (user !== null) {
  //       this.currentUser = user?.profile?.name?.toString();
  //       this.accessTokenExpired = user.expired;
  //     }

  //     this.isLoggedIn = user !== null && !user.expired;
  //   });
  // }
}
